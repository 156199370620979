import React, { useEffect, useState } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Empty, Table } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Error, Loading } from '../../ui'

const PAGE_SIZE = 50

export const Products = () => {
	const navigate = useNavigate()
	const [params, setParams] = useSearchParams()
	const id = params.get('id')
	const page = params.get('page') || 1
	
	if (!id) navigate('/categories')
	
	let skip = (parseInt(page) - 1) * PAGE_SIZE
	if (skip < 0) skip = 0
	
	const [total, setTotal] = useState(PAGE_SIZE)
	const [products, setProducts] = useState([])
	
	const { data, loading, error, refetch } = useQuery(GETTER, { variables: { id, skip }, fetchPolicy: 'cache-and-network' })
	
	const columns = [
		{
			title: 'Наименование', dataIndex: 'title', key: 'title',
			render: (title, record) => <Link to={`/categories/products/edit?id=${record.id}`}>{title}</Link>
		},
		{
			title: 'Цена', dataIndex: 'price', key: 'price', width: 100, align: 'center',
			render: price => <div>{price.toFixed(0)}</div>
		},
		{
			title: 'Активность', dataIndex: 'active', key: 'active', width: 100, align: 'center',
			render: active => active ? <CheckCircleOutlined style={{ color: '#52c41a' }}/> : <CloseCircleOutlined style={{ color: '#ff4d4f' }}/>
		}
	]
	
	useEffect(() => {
		if (data) {
			setProducts([ ...data.getProducts ])
			setTotal(data.getProductsTotal)
		}
	}, [data])
	
	useEffect(() => {
		refetch()
	}, [page])
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading && !products.length) return <Loading/>
	
	return <div style={{ padding: '0 24px 0' }}>
		
		{!!products.length ? <>
			<Table
				rowKey='id'
				dataSource={products}
				columns={columns}
				style={{ marginTop: 16 }}
				pagination={{
					defaultCurrent: page,
					position: ['topRight', 'bottomRight'],
					pageSize: PAGE_SIZE,
					total,
					showSizeChanger: false,
					onChange: page => {
						setParams({ id, page })
					}
				}}
			/>
		</> : <Empty
			image={Empty.PRESENTED_IMAGE_SIMPLE}
			description={'У Вас нет созданных товаров'}
		/>}
	</div>
}

const GETTER = gql`
	query($id: ID!, $skip: Int) {
		getProducts(id: $id, skip: $skip) {
			id
			active
			title
			price
			parent {
				id
				title
			}
		}
		getProductsTotal(id: $id)
	}
`
