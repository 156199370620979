import React, { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Empty, Table, Typography } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Error, Loading } from '../../ui'

const pageSize = 50

export const Clients = () => {
	
	const [skip, setSkip] = useState(0)
	const [total, setTotal] = useState(50)
	
	const [state, setState] = useState([])
	
	const { data, loading, error, refetch } = useQuery(GETTER, { variables: { skip }, fetchPolicy: 'cache-and-network' })
	
	const columns = [
		{
			title: 'ФИО', dataIndex: 'name', key: 'name',
			render: (_, record) => {
				const name = `${record.lastname} ${record.firstname} ${record.patronymic}`
				return !!name.trim().length ? name : <Typography.Text type='secondary' italic>--- не указано ---</Typography.Text>
			}
		},
		{
			title: 'Телефон', dataIndex: 'phone', key: 'phone', width: 150, align: 'center',
			render: title => title
		},
		{
			title: 'Дисконтная карта', dataIndex: 'discount.number', key: 'discount.number', width: 250, align: 'center',
			render: (_, record) => record.discount?.number || <Typography.Text type='secondary' italic>регистрация не закончена</Typography.Text>
		},
		{
			title: 'Уровень', dataIndex: 'discount.level', key: 'discount.level', width: 150, align: 'right',
			render: (_, record) => record.discount ? record.discount.level : <Typography.Text type='secondary' italic>---</Typography.Text>
		},
		{
			title: 'Накопления', dataIndex: 'discount.accumulation', key: 'discount.accumulation', width: 150, align: 'right',
			render: (_, record) => record.discount ? String(record.discount.accumulation).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : <Typography.Text type='secondary' italic>---</Typography.Text>
		},
		{
			title: 'Активность', dataIndex: 'discount', key: 'discount', width: 200, align: 'center',
			render: active => active ? <CheckCircleOutlined style={{ color: '#52c41a' }}/> : <CloseCircleOutlined style={{ color: '#ff4d4f' }}/>
		}
	]
	
	useEffect(() => {
		if (data) {
			setState([ ...data.getClients ])
			setTotal(data.getClientsTotal)
		}
	}, [data])
	
	useEffect(() => {
		refetch()
	}, [skip])
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading && !state.length) return <Loading/>
	
	return <div style={{ padding: '0 24px 0' }}>
		
		{!!state.length ? <>
			<Table
				rowKey='id'
				dataSource={state}
				columns={columns}
				style={{ marginTop: 16 }}
				pagination={{
					position: ['topRight', 'bottomRight'],
					pageSize,
					total,
					showSizeChanger: false,
					onChange: page => {
						setSkip((page - 1) * pageSize)
					}
				}}
			/>
		</> : <Empty
			image={Empty.PRESENTED_IMAGE_SIMPLE}
			description={'У Вас нет клиентов'}
		/>}
	</div>
}

const GETTER = gql`
	query($skip: Int) {
		getClients(skip: $skip) {
			id
			lastname
			firstname
			patronymic
			phone
			discount {
				id
				number
				level
				accumulation
			}
		}
		getClientsTotal
	}
`
