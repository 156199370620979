import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { apollo } from './heplers'
import Routes from './Routes'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<React.StrictMode>
		<ApolloProvider client={apollo}>
			<BrowserRouter>
				<Routes/>
			</BrowserRouter>
		</ApolloProvider>
	</React.StrictMode>
)
