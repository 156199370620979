import React, { useState } from 'react'
import { useNavigate, useLocation, Outlet, Link } from 'react-router-dom'
import { Layout, Menu, Space, Dropdown, Breadcrumb } from 'antd'
import { ShopOutlined, SendOutlined, UserOutlined, SettingOutlined, HomeOutlined, CalendarOutlined } from '@ant-design/icons'

const i = {
	sidebar: {
		news: 'Новости',
		categories: 'Категории',
		clients: 'Клиенты',
		pushes: 'Push-рассылки',
		settings: 'Настройки'
	}
}

const breadcrumbsMap = {
	'/categories': 'Категории',
	'/categories/add': 'Создание категории',
	'/categories/edit': 'Редактирование категории',
	'/categories/products': 'Список товаров',
	'/categories/products/edit': 'Редактирование товара',
	'/news': 'Новости',
	'/news/add': 'Создание новости',
	'/news/edit': 'Редактирование новости',
	'/clients': 'Клиенты',
	'/pushes': 'Push-рассылки',
	'/settings': 'Настройки'
}

export const Main = () => {
	const year = new Date().getFullYear()
	
	const [collapsed, setCollapsed] = useState(false)
	const navigate = useNavigate()
	const location = useLocation()
	
	const pathSnippets = location.pathname.split('/').filter(i => i)
	const breadcrumbs = pathSnippets.map((_, index) => {
		const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
		return <Breadcrumb.Item key={url}>
			{pathSnippets.length - 1 === index ? breadcrumbsMap[url] : <Link to={url}>{breadcrumbsMap[url]}</Link>}
		</Breadcrumb.Item>
	})
	
	const onClickMenu = ({ key }) => {
		if (key) navigate(key)
	}
	
	const onClickDropdown = ({ key }) => {
		if (key === 'settings') navigate('/settings')
		else if (key === 'exit') {
			localStorage.removeItem('@token')
			navigate('/login')
		}
	}
	
	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Layout.Sider
				style={{ boxShadow: '2px 0 8px 0 rgb(29 35 41 / 5%)' }}
				theme='light'
				collapsible
				collapsed={collapsed}
				onCollapse={() => setCollapsed(!collapsed)}
			>
				{!collapsed && <img src={'/images/logo.svg'} style={{ width: '168px', margin: '16px' }} alt=''/>}
				
				<Menu
					theme='light'
					mode='inline'
					defaultSelectedKeys={[location.pathname]}
					inlineIndent={20}
					onClick={onClickMenu}
					items={[
						{ key: '/news', label: i.sidebar.news, icon: <CalendarOutlined/> },
						{ key: '/categories', label: i.sidebar.categories, icon: <ShopOutlined/> },
						{ key: '/clients', label: i.sidebar.clients, icon: <UserOutlined/> },
						{ key: '/pushes', label: i.sidebar.pushes, icon: <SendOutlined/> },
						{ key: '/settings', label: i.sidebar.settings, icon: <SettingOutlined/> }
					
					]}
				/>
			</Layout.Sider>
			<Layout>
				<Layout.Header style={{ padding: '0 20px', display: 'flex', justifyContent: 'space-between', backgroundColor: '#ffffff' }}>
					<div/>
					<Space>
						<Dropdown
							menu={{
								items: [
									{ key: 'settings', label: 'Настройки' },
									{ key: 'exit', label: 'Выход', danger: true }
								],
								onClick: onClickDropdown
							}}
						>
							<div style={{ display: 'flex', alignItems: 'center', height: '64px', padding: '0 12px', cursor: 'pointer', transition: 'all .3s' }}>
								<SettingOutlined style={{ fontSize: '20px', color: '#1890ff' }}/>
							</div>
						</Dropdown>
					</Space>
				</Layout.Header>
				
				<Breadcrumb style={{ padding: '28px 24px 0' }}>
					<Breadcrumb.Item>
						<HomeOutlined/>
					</Breadcrumb.Item>
					{breadcrumbs}
				</Breadcrumb>
				
				<Layout.Content>
					<Outlet/>
				</Layout.Content>
				<Layout.Footer style={{ textAlign: 'center' }}>Стрижамент © {year} Техническая поддержка <a href='https://trueapp.ru' target='_blank'>trueapp.ru</a></Layout.Footer>
			</Layout>
		</Layout>
	)
}
