import { Button } from 'antd'

export const Error = ({ error, refetch }) => (
	<div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 215px)' }}>
		
		{error.message}
		<div style={{ paddingTop: '16px' }}>
			<Button
				type="primary"
				onClick={() => refetch?.()}
			>
				Обновить
			</Button>
		</div>
	</div>
)
