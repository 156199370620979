import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Row, Col, Form, Input, notification } from 'antd'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Loading, Error } from '../../ui'

const i = {
	buttons: {
		update: 'Сохранить'
	},
	labels: {
		username: 'Пользователь',
		password: 'Пароль',
		host: 'Сервер'
	},
	placeholders: {
		host: 'Введите адрес сервера 1C в формате https://site.ru, если есть ПОРТ, то укажите и его',
		username: 'Введите логин',
		password: 'Введите пароль'
	},
	required: 'Это обязательное поле!',
	notifications: {
		success: 'Успех!',
		error: 'Ошибка!',
		create: 'Новость успешно создана!'
	}
}

export const Settings = () => {
	const [form] = Form.useForm(null)
	
	const [state, setState] = useState({ id: '', updating: false })
	
	const { data, error, loading, refetch } = useQuery(SETTINGS, { fetchPolicy: 'network-only' })
	
	const [UpdateSettings] = useMutation(UPDATE_SETTINGS)
	
	const settings = data?.getSettings
	
	useEffect(() => {
		if (settings) setState({ ...state, id: settings.id })
	}, [settings])
	
	
	const openNotification = ( type, message, description ) => notification[type]({ message, description })
	
	const update = values => {
		setState({ ...state, updating: true })
		UpdateSettings({
			variables: {
				id: state.id,
				username: values.username,
				password: values.password,
				host: values.host
			}
		}).then(() => {
			setState({ ...state, updating: false })
			openNotification('success', i.notifications.success, i.notifications.update)
		}).catch(e => {
			console.log(e)
			setState({ ...state, updating: false })
			openNotification('error', i.notifications.error, e.message)
		})
	}
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading && !data) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		<div>
			<Button
				type='primary'
				loading={loading || state.updating}
				disabled={loading || state.updating}
				onClick={() => form.submit()}
			>
				{i.buttons.update}
			</Button>
		</div>
		
		<Card style={{ marginTop: 16 }}>
			
			<Form
				form={form}
				name='settings'
				layout='vertical'
				initialValues={settings}
				onFinish={update}
			>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name='host'
							label={i.labels.host}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.host}/>
						</Form.Item>
						
						<Form.Item
							name='username'
							label={i.labels.username}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.username}/>
						</Form.Item>
						
						
						<Form.Item
							name='password'
							label={i.labels.password}
							rules={[{ required: true, message: i.required }]}
						>
							<Input.Password placeholder={i.placeholders.password}/>
						</Form.Item>
						
					</Col>
				</Row>
			</Form>
		
		</Card>
	
	</div>
}


const SETTINGS = gql`
	query {
		getSettings {
			id
			host
			username
			password
		}
	}
`
const UPDATE_SETTINGS = gql`
	mutation ($id: ID!, $host: String, $username: String, $password: String) {
		updateSettings(id: $id, host: $host, username: $username, password: $password) {
			id
		}
	}
`