import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Empty, Button, Table } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Loading, Error } from '../../ui'

export const News = () => {
	const navigate = useNavigate()
	
	const { data, loading, error, refetch } = useQuery(GETTER, { fetchPolicy: 'network-only' })
	
	const columns = [{ title: 'Название', dataIndex: 'title', key: 'title', render: (title, record) => <Link to={`/news/edit?id=${record.id}`}>{title}</Link> }, {
		title: 'Активность',
		dataIndex: 'active',
		key: 'active',
		render: active => active ? <CheckCircleOutlined style={{ color: '#52c41a' }}/> : <CloseCircleOutlined style={{ color: '#ff4d4f' }}/>,
		width: 200
	}]
	
	const news = data?.getNews
	
	const goAdd = () => navigate('/news/add')
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading) return <Loading/>
	
	return (<div style={{ padding: '28px 24px 0' }}>
		<div>
			<Button
				type="primary"
				onClick={goAdd}
			>
				Создать
			</Button>
		</div>
		
		{!!news.length ? <Table
			rowKey="id"
			dataSource={news}
			columns={columns}
			style={{ marginTop: 16 }}
		
		/> : <Empty
			image={Empty.PRESENTED_IMAGE_SIMPLE}
			description={'У Вас нет созданных новостей'}
		/>}
	</div>)
}

const GETTER = gql`
	query {
		getNews {
			id
			active
			image
			title
			description
			sort
		}
	}
`