import { Empty } from 'antd'

export const Pushes = () => {

	return (
		<div style={{ padding: '28px 24px 0' }}>
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={'В разработке'}
			/>
		</div>
	)
}
