import React, { useEffect, useState } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { Main, Login } from './layouts'
import { Categories, Category, Clients, Product, Products, Pushes, News, New, Settings } from './pages'
import { FullScreenLoading } from './ui'

export default () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const [loading, setLoading] = useState(true)
	
	useEffect(() => {
		if (pathname === '/') navigate('/news', { replace: true })
	}, [pathname])
	
	useEffect(() => {
		const token = localStorage.getItem('@token')
		if (!token) navigate('/login')
		setLoading(false)
	}, [])
	
	if (loading) return <FullScreenLoading/>
	
	return (
		<Routes>
			<Route path={'/'} element={<Main/>}>
				<Route path={'news'} element={<News/>}/>
				<Route path={'news/add'} element={<New type={'add'}/>}/>
				<Route path={'news/edit'} element={<New type={'edit'}/>}/>
				<Route path={'categories'} element={<Categories/>}/>
				<Route path={'categories/add'} element={<Category type={'add'}/>}/>
				<Route path={'categories/edit'} element={<Category type={'edit'}/>}/>
				<Route path={'categories/products'} element={<Products/>}/>
				<Route path={'categories/products/edit'} element={<Product/>}/>
				<Route path={'clients'} element={<Clients/>}/>
				<Route path={'pushes'} element={<Pushes/>}/>
				<Route path={'settings'} element={<Settings/>}/>
			</Route>
			<Route path={'login'} element={<Login/>}/>
		</Routes>
	)
}
