import { ApolloClient, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { createUploadLink } from 'apollo-upload-client'
import { isDev } from './isDev'
import config from '../config'

const httpLink = () => createUploadLink({
	uri: isDev ? config.server.development : config.server.production,
	credentials: 'same-origin'
})

const retryLink = new RetryLink({
	delay: {
		initial: 5000,
		max: 10000,
		jitter: true
	},
	attempts: {
		max: Infinity,
		retryIf: error => false
	}
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, extensions }) => {
			console.log(`[GraphQL error]: Message: ${message}, Code: ${extensions?.code}`)
			// TODO если неправильные права, то деавторизует!
			if (extensions?.code === 'UNAUTHENTICATED') {
				localStorage.removeItem('@token')
				window.location = '/'
			}
		})
	}
	if (networkError) {
		console.log(`[Network error]: ${networkError}`)
	}
})

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('@token')
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : ''
		}
	}
})

export const apollo = new ApolloClient({
	link: retryLink.concat(errorLink.concat(authLink).concat(httpLink())),
	cache: new InMemoryCache()
})
