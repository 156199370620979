import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Empty, Button, Table, notification } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Loading, Error } from '../../ui'

export const Categories = () => {
	const navigate = useNavigate()
	const [sync, setSync] = useState({ categories: false, products: false })
	
	const { data, loading, error, refetch } = useQuery(GETTER, { fetchPolicy: 'network-only' })
	const [SyncCategories] = useMutation(SYNC_CATEGORIES)
	const [SyncProducts] = useMutation(SYNC_PRODUCTS)
	
	const columns = [
		{
			title: 'Название', dataIndex: 'title', key: 'title',
			render: (title, record) => <Link to={`/categories/products?id=${record.id}`}>{title}</Link>
		},
		{
			title: 'Операции', key: 'edit', width: 100, align: 'center',
			render: (title, record) => <EditOutlined style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => navigate(`/categories/edit?id=${record.id}`)}/>
		},
		{
			title: 'Активность', dataIndex: 'active', key: 'active', width: 100, align: 'center',
			render: active => active ? <CheckCircleOutlined style={{ color: '#52c41a' }}/> : <CloseCircleOutlined style={{ color: '#ff4d4f' }}/>
		}
	]
	
	const categories = data?.getCategories
	const synchronizationStatus = data?.getSynchronizationStatus
	
	const openNotification = (type, message, description) => notification[type]({ message, description })
	
	const goAdd = () => navigate('/categories/add')
	
	const goSyncCategories = () => {
		setSync({ ...sync, categories: true })
		SyncCategories()
			.then(({ data: { syncCategories } }) => {
				if (syncCategories.type === 'success') openNotification('success', 'Успех!', 'Синхронизация внешних категорий прошла успешно!')
				else openNotification('error', 'Ошибка!', syncCategories.message)
				setSync({ ...sync, categories: false })
			})
			.catch(e => {
				console.log(e)
				openNotification('error', 'Ошибка!', e.message)
				setSync({ ...sync, categories: false })
			})
	}
	
	const goSyncProducts = () => {
		setSync({ ...sync, products: true })
		SyncProducts()
			.then(({ data: { syncProducts } }) => {
				if (syncProducts.type === 'success') openNotification('success', 'Успех!', 'Задача по синхронизации товаров создана!')
				else openNotification('error', 'Ошибка!', syncProducts.message)
				setSync({ ...sync, products: false })
			})
			.catch(e => {
				console.log(e)
				openNotification('error', 'Ошибка!', e.message)
				setSync({ ...sync, products: false })
			})
		setTimeout(() => {
			document.location.reload()
		}, 1000)
	}
	
	useEffect(() => {
		if (synchronizationStatus?.status === 'processing') {
			setSync({ ...sync, products: true })
			setTimeout(() => {
				refetch?.()
			}, 15000)
		} else if (synchronizationStatus?.status === 'success') {
			setSync({ ...sync, products: false })
		}
	}, [synchronizationStatus])
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		<div>
			<Button
				type='primary'
				onClick={goAdd}
				disabled={sync.categories || sync.products}
			>
				Создать
			</Button>
			
			<Button
				style={{ marginLeft: 12 }}
				disabled={sync.products}
				loading={sync.categories}
				onClick={goSyncCategories}
			>
				Синхронизация внешних категорий
			</Button>
			
			<Button
				style={{ marginLeft: 12 }}
				disabled={sync.categories}
				loading={sync.products}
				onClick={goSyncProducts}
			>
				Синхронизация товаров
			</Button>
		</div>
		
		{!!categories.length ? <Table
			rowKey='id'
			dataSource={categories}
			columns={columns}
			style={{ marginTop: 16 }}
			pagination={{ pageSize: 50 }}
		/> : <Empty
			image={Empty.PRESENTED_IMAGE_SIMPLE}
			description={'У Вас нет созданных категорий'}
		/>}
	</div>
}

const GETTER = gql`
	query {
		getCategories {
			id
			active
			title
			image
		}
		getSynchronizationStatus {
			status
		}
	}
`

const SYNC_CATEGORIES = gql`
	mutation {
		syncCategories {
			message
			type
		}
	}
`

const SYNC_PRODUCTS = gql`
	mutation {
		syncProducts {
			message
			type
		}
	}
`
