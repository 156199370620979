import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Checkbox, Layout, Flex } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'

export const Login = () => {
	const [form] = Form.useForm()
	const [AuthUser] = useMutation(AUTH_USER)
	const navigate = useNavigate()
	
	const onFinish = values => {
		AuthUser({
			variables: {
				email: values.email,
				password: values.password
			}
		}).then(({ data: { authUser: { jwt } } }) => {
			localStorage.setItem('@token', jwt)
			navigate('/news')
		}).catch(e => {
			const error = { code: e.networkError.result.errors[0].extensions.code, message: e.networkError.result.errors[0].message }
			form.setFields([{
				name: error.code === 'LOGIN_ERROR' ? 'email' : 'password',
				errors: [error.message]
			}])
		})
	}
	
	useEffect(() => {
		const token = localStorage.getItem('@token')
		if (token) navigate('/news')
	}, [])
	
	return (
		<Flex gap='middle' wrap='wrap'>
			<Layout style={{ minHeight: '100vh' }}>
				<Row type='flex' align='middle' justify='center' style={{ height: '100%' }}>
					<Col>
						<img src={'images/logo.svg'} style={{ width: 200, height: 35, display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '24px' }} alt=''/>
						<Form
							form={form}
							style={{ width: '300px' }}
							name='basic'
							initialValues={{ remember: true }}
							onFinish={onFinish}
							autoComplete='off'
						>
							<Form.Item
								name='email'
								rules={[
									{
										required: true,
										message: 'Введите ваш email!'
									}
								]}
							>
								<Input
									prefix={<MailOutlined/>}
									placeholder='Email'
								/>
							</Form.Item>
							
							<Form.Item
								name='password'
								rules={[
									{
										required: true,
										message: 'Введите ваш пароль!'
									}
								]}
							>
								<Input.Password
									prefix={<LockOutlined/>}
									type='password'
									placeholder='Пароль'
								/>
							</Form.Item>
							
							<Form.Item
								name='remember'
								valuePropName='checked'
							>
								<Checkbox>Запомнить</Checkbox>
							</Form.Item>
							
							<Form.Item>
								<Button type='primary' htmlType='submit' style={{ width: '100%' }}>
									Войти
								</Button>
							</Form.Item>
						
						</Form>
					</Col>
				</Row>
			</Layout>
		</Flex>
	
	)
}

const AUTH_USER = gql`
	mutation ($email: String, $password: String) {
		authUser(email: $email, password: $password) {
			id
			jwt
		}
	}
`
