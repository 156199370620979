import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Card, Row, Col, Form, Input, InputNumber, Switch, notification, Popconfirm, Image, Upload } from 'antd'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { CheckOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons'
import ImageCrop from 'antd-img-crop'
import { Loading, Error } from '../../ui'
import { isDev } from '../../heplers'
import config from '../../config'

const i = {
	buttons: {
		create: 'Создать',
		update: 'Сохранить',
		remove: 'Удалить'
	},
	labels: {
		active: 'Активность',
		title: 'Название новости',
		description: 'Описание новости',
		sort: 'Сортировка'
	},
	placeholders: {
		title: 'Введите название новости',
		description: 'Введите описание новости'
	},
	popup: {
		title: 'Подтвердите удаление новости!',
		cancel: 'Отмена',
		ok: 'Удалить'
	},
	required: 'Это обязательное поле!',
	notifications: {
		success: 'Успех!',
		error: 'Ошибка!',
		create: 'Новость успешно создана!',
		update: 'Новость успешно сохранена!',
		remove: 'Новость успешно удалена!'
	},
	image: {
		info: 'Идеальное соотношение сторон 5:3 (например, 750:450). Формат JPG или PNG',
		upload: 'Загрузка изображения',
		modal: {
			title: 'Редактирование изображения',
			ok: 'Загрузить',
			cancel: 'Отмена',
			reset: 'Отменить изменения'
		}
	}
}

export const New = ({ type }) => {
	const navigate = useNavigate()
	
	const [params] = useSearchParams()
	const id = params.get('id')
	const [form] = Form.useForm()
	
	const [state, setState] = useState({ image: '', fetching: true, creating: false, updating: false, removing: false, imaging: false })
	
	const [GetNews, { data, error, refetch }] = useLazyQuery(GET_NEWS, { fetchPolicy: 'network-only' })
	
	const [CreateNews] = useMutation(CREATE_NEWS)
	const [UpdateNews] = useMutation(UPDATE_NEWS)
	const [RemoveNews] = useMutation(REMOVE_NEWS)
	
	const news = data?.getNew
	
	useEffect(() => {
		if (news) setState({ ...state, fetching: false, image: news.image })
	}, [news])
	
	useEffect(() => {
		if (type === 'edit') {
			GetNews({ variables: { id } }).catch(e => console.log(e))
		} else setState({ ...state, fetching: false })
	}, [])
	
	const openNotification = (type, message, description) => notification[type]({ message, description })
	
	const create = values => {
		setState({ ...state, creating: true })
		CreateNews({
			variables: {
				title: values.title,
				description: values.description,
				active: values.active,
				sort: values.sort || 0,
				image: state.image
			}
		}).then(() => {
			setState({ ...state, creating: false })
			openNotification('success', i.notifications.success, i.notifications.create)
			navigate('/news')
		}).catch(e => {
			console.log(e)
			openNotification('error', i.notifications.error, e.message)
			setState({ ...state, creating: false })
		})
	}
	
	const update = values => {
		setState({ ...state, updating: true })
		UpdateNews({
			variables: {
				id,
				title: values.title,
				description: values.description,
				active: values.active,
				sort: values.sort || 0,
				image: state.image
			}
		}).then(() => {
			setState({ ...state, updating: false })
			openNotification('success', i.notifications.success, i.notifications.update)
			navigate('/news')
		}).catch(e => {
			console.log(e)
			setState({ ...state, updating: false })
			openNotification('error', i.notifications.error, e.message)
		})
	}
	
	const remove = () => {
		setState({ ...state, removing: true })
		RemoveNews({
			variables: {
				id
			}
		}).then(() => {
			setState({ ...state, removing: false })
			openNotification('success', i.notifications.success, i.notifications.remove)
			navigate('/news')
		}).catch(e => {
			console.log(e)
			openNotification('error', i.notifications.error, e.message)
			setState({ ...state, removing: false })
		})
	}
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (state.fetching) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		<div>
			<Button
				type='primary'
				loading={type === 'edit' ? state.updating : state.creating}
				disabled={type === 'edit' ? (state.updating || state.removing) : state.creating}
				onClick={() => form.submit()}
			>
				{type === 'edit' ? i.buttons.update : i.buttons.create}
			</Button>
			{type === 'edit' && <Popconfirm placement='top' title={i.popup.title} okText={i.popup.ok} cancelText={i.popup.cancel} onConfirm={remove}>
				<Button
					style={{ marginLeft: 12 }}
					danger
					loading={state.removing}
					disabled={state.removing || state.creating || state.updating}
				>
					{i.buttons.remove}
				</Button>
			</Popconfirm>}
		</div>
		
		<Card style={{ marginTop: 16 }}>
			
			<Form
				form={form}
				name='news'
				layout='vertical'
				initialValues={type === 'edit' ? news : undefined}
				onFinish={type === 'edit' ? update : create}
			>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item name='active' label={i.labels.active} valuePropName='checked'>
							<Switch checkedChildren={<CheckOutlined/>} unCheckedChildren={<CloseOutlined/>}/>
						</Form.Item>
						
						<Form.Item
							name='title'
							label={i.labels.title}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.title}/>
						</Form.Item>
						
						<Form.Item name='description' label={i.labels.description}>
							<Input.TextArea rows={4} placeholder={i.placeholders.description}/>
						</Form.Item>
						
						<Form.Item name='sort' label={i.labels.sort}>
							<InputNumber type='number' precision={0}/>
						</Form.Item>
					</Col>
					
					<Col span={12}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
							<ImageCrop
								aspect={1}
								quality={1}
								maxZoom={5}
								modalTitle={i.image.modal.title}
								modalOk={i.image.modal.ok}
								modalCancel={i.image.modal.cancel}
								resetText={i.image.modal.reset}
								aspectSlider={true}
								showGrid={true}
								rotationSlider={true}
								showReset={true}
								modalWidth={720}
							>
								<Upload
									listType={'picture'}
									maxCount={1}
									showUploadList={false}
									action={`${isDev ? config.server.development : config.server.production}/images/news/upload`}
									onChange={({ file: { response } }) => {
										if (response) setState({ ...state, image: response.filename })
									}}
								>
									<Button icon={<UploadOutlined/>}>{i.image.upload}</Button>
								</Upload>
							</ImageCrop>
							<div style={{ marginLeft: 12, color: '#595959' }}>{i.image.info}</div>
						</div>
						{!!state.image && <Image src={`${isDev ? config.server.development : config.server.production}/images/news/${state.image}`}/>}
					</Col>
				</Row>
			</Form>
		
		</Card>
	
	</div>
}


const GET_NEWS = gql`
	query($id: ID!) {
		getNew(id: $id) {
			id
			active
			image
			title
			description
			sort
		}
	}
`

const CREATE_NEWS = gql`
	mutation ($title: String, $description: String, $image: String, $active: Boolean, $sort: Int) {
		createNews(title: $title, description: $description, image: $image, active: $active, sort: $sort) {
			id
		}
	}
`

const UPDATE_NEWS = gql`
	mutation ($id: ID!, $title: String, $description: String, $image: String, $active: Boolean, $sort: Int) {
		updateNews(id: $id, title: $title, description: $description, image: $image, active: $active, sort: $sort) {
			id
		}
	}
`

const REMOVE_NEWS = gql`
	mutation ($id: ID!) {
		removeNews(id: $id) {
			id
		}
	}
`

