import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Button, Card, Col, Flex, Form, Image, Input, InputNumber, notification, Row, Switch, Upload } from 'antd'
import ImageCrop from 'antd-img-crop'
import { gql, useMutation, useQuery } from '@apollo/client'
import { CheckOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons'
import { Error, Loading } from '../../ui'
import { isDev } from '../../heplers'
import config from '../../config'

const i = {
	buttons: {
		update: 'Сохранить',
		back: 'Назад'
	},
	labels: {
		active: 'Активность',
		title: 'Наименование товара',
		description: 'Описание товара',
		keywords: 'Ключевые слова для поиска',
		sort: 'Сортировка'
	},
	placeholders: {
		title: 'Введите наименование товара',
		description: 'Введите описание товара',
		keywords: 'Введите ключевые слова через запятую или через пробел'
	},
	image: {
		info: 'Формат JPG или PNG',
		upload: 'Загрузка изображения',
		modal: {
			title: 'Редактирование изображения',
			ok: 'Загрузить',
			cancel: 'Отмена',
			reset: 'Отменить изменения'
		}
	},
	required: 'Это обязательное поле!',
	notifications: {
		update: {
			title: 'Успех!',
			description: 'Товар успешно сохранён!'
		},
		error: {
			title: 'Ошибка!'
		}
	}
}

export const Product = () => {
	const navigate = useNavigate()
	const [params] = useSearchParams()
	const id = params.get('id')
	const [form] = Form.useForm()
	
	const [state, setState] = useState({ image: '' })
	
	const { data, error, loading, refetch } = useQuery(GET, { variables: { id }, fetchPolicy: 'network-only' })
	
	const [Update] = useMutation(UPDATE)
	
	const product = data?.getProduct
	
	const openNotification = (type, message, description) => notification[type]({ message, description })
	
	const update = values => {
		Update({
			variables: {
				id,
				title: values.title,
				description: values.description,
				keywords: values.keywords,
				active: values.active,
				sort: values.sort || 0,
				image: state.image || ''
			}
		}).then(() => {
			setState({ ...state, updating: false })
			openNotification('success', i.notifications.update.title, i.notifications.update.description)
		}).catch(e => {
			console.log(e)
			setState({ ...state, updating: false })
			openNotification('error', i.notifications.error.title, e.message)
		})
	}
	
	useEffect(() => {
		if (product) {
			setState({ ...state, image: product.image })
		}
	}, [product])
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading) return <Loading/>
	
	return product
		? <div style={{ padding: '28px 24px 0' }}>
			<Flex gap='small' wrap>
				<Button
					type='primary'
					loading={loading}
					disabled={loading}
					onClick={() => form.submit()}
				>
					{i.buttons.update}
				</Button>
				<Button
					loading={loading}
					disabled={loading}
					onClick={() => navigate(-1)}
				>
					{i.buttons.back}
				</Button>
			</Flex>
			
			<Card style={{ marginTop: 16 }}>
				
				
				<Row gutter={16}>
					<Col span={12}>
						<Form
							form={form}
							name='product'
							layout='vertical'
							initialValues={product}
							onFinish={props => update(props)}
						>
							<Form.Item name='active' label={i.labels.active} valuePropName='checked'>
								<Switch checkedChildren={<CheckOutlined/>} unCheckedChildren={<CloseOutlined/>}/>
							</Form.Item>
							
							<Form.Item
								name='title'
								label={i.labels.title}
								rules={[{ required: true, message: i.required }]}
							>
								<Input placeholder={i.placeholders.title}/>
							</Form.Item>
							
							<Form.Item name='description' label={i.labels.description}>
								<Input.TextArea rows={4} placeholder={i.placeholders.description}/>
							</Form.Item>
							
							<Form.Item name='keywords' label={i.labels.keywords}>
								<Input placeholder={i.placeholders.keywords}/>
							</Form.Item>
							
							<Form.Item name='sort' label={i.labels.sort}>
								<InputNumber type='number' precision={0}/>
							</Form.Item>
						</Form>
					</Col>
					
					<Col span={12}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
							<ImageCrop
								aspect={1}
								quality={1}
								maxZoom={5}
								modalTitle={i.image.modal.title}
								modalOk={i.image.modal.ok}
								modalCancel={i.image.modal.cancel}
								resetText={i.image.modal.reset}
								aspectSlider={true}
								showGrid={true}
								rotationSlider={true}
								showReset={true}
								modalWidth={720}
							>
								<Upload
									listType={'picture'}
									maxCount={1}
									showUploadList={false}
									action={`${isDev ? config.server.development : config.server.production}/images/products/upload`}
									onChange={({ file: { response } }) => {
										if (response) setState({ ...state, image: response.filename })
									}}
								>
									<Button icon={<UploadOutlined/>}>{i.image.upload}</Button>
								</Upload>
							</ImageCrop>
							<div style={{ marginLeft: 12, color: '#595959' }}>{i.image.info}</div>
						</div>
						
						{!!state.image && <Image src={`${isDev ? config.server.development : config.server.production}/images/products/${state.image}`}/>}
					</Col>
				</Row>
			
			</Card>
		</div>
		: null
}

const GET = gql`
	query($id: ID!) {
		getProduct(id: $id) {
			id
			active
			title
			description
			keywords
			sort
			image
			parent {
				id
			}
		}
	}
`

const UPDATE = gql`
	mutation ($id: ID!, $title: String, $description: String, $keywords: String, $active: Boolean, $sort: Int, $image: String) {
		updateProduct(id: $id, title: $title, description: $description, keywords: $keywords, active: $active, sort: $sort, image: $image) {
			id
		}
	}
`
