import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Card, Row, Col, Form, Input, InputNumber, Switch, notification, Popconfirm, Select, Flex } from 'antd'
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Loading, Error } from '../../ui'

const i = {
	buttons: {
		create: 'Создать',
		update: 'Сохранить',
		back: 'Назад',
		remove: 'Удалить'
	},
	labels: {
		active: 'Активность',
		title: 'Название категории',
		description: 'Описание категории',
		sort: 'Сортировка',
		integrations: 'Внешние категории'
	},
	placeholders: {
		title: 'Введите название категории',
		description: 'Введите описание категории',
		integrations: 'Выберите внешние категории'
	},
	popup: {
		title: 'Подтвердите удаление категории!',
		cancel: 'Отмена',
		ok: 'Удалить'
	},
	required: 'Это обязательное поле!',
	notifications: {
		success: 'Успех!',
		error: 'Ошибка!',
		create: 'Категория успешно создана!',
		update: 'Категория успешно сохранена!',
		remove: 'Категория успешно удалена!'
	}
}

export const Category = ({ type }) => {
	const navigate = useNavigate()
	
	const [params] = useSearchParams()
	const id = params.get('id')
	const [form] = Form.useForm()
	
	const [state, setState] = useState({ fetching: true, creating: false, updating: false, removing: false })
	
	const { data: dataSyncCategories } = useQuery(SYNC_CATEGORIES, { fetchPolicy: 'network-only' })
	const [GetCategory, { data, error, refetch }] = useLazyQuery(GET_CATEGORY, { fetchPolicy: 'network-only' })
	
	const [CreateCategory] = useMutation(CREATE_CATEGORY)
	const [UpdateCategory] = useMutation(UPDATE_CATEGORY)
	const [RemoveCategory] = useMutation(REMOVE_CATEGORY)
	
	const syncCategories = dataSyncCategories?.getSyncCategories
	const category = data?.getCategory
	
	useEffect(() => {
		if (category) setState({ ...state, fetching: false })
	}, [category])
	
	useEffect(() => {
		if (type === 'edit') {
			GetCategory({ variables: { id } }).catch(e => console.log(e))
		} else setState({ ...state, fetching: false })
	}, [])
	
	const openNotification = (type, message, description) => notification[type]({ message, description })
	
	const create = values => {
		setState({ ...state, creating: true })
		CreateCategory({
			variables: {
				title: values.title,
				description: values.description,
				active: values.active,
				sort: values.sort || 0,
				integrations: values.integrations
			}
		}).then(() => {
			setState({ ...state, creating: false })
			openNotification('success', i.notifications.success, i.notifications.create)
			navigate('/categories')
		}).catch(e => {
			console.log(e)
			openNotification('error', i.notifications.error, e.message)
			setState({ ...state, creating: false })
		})
	}
	
	const update = values => {
		setState({ ...state, updating: true })
		UpdateCategory({
			variables: {
				id,
				title: values.title,
				description: values.description,
				active: values.active,
				sort: values.sort || 0,
				integrations: values.integrations
			}
		}).then(() => {
			setState({ ...state, updating: false })
			openNotification('success', i.notifications.success, i.notifications.update)
			navigate('/categories')
		}).catch(e => {
			console.log(e)
			setState({ ...state, updating: false })
			openNotification('error', i.notifications.error, e.message)
		})
	}
	
	const remove = () => {
		setState({ ...state, removing: true })
		RemoveCategory({
			variables: {
				id
			}
		}).then(() => {
			setState({ ...state, removing: false })
			openNotification('success', i.notifications.success, i.notifications.remove)
			navigate('/categories')
		}).catch(e => {
			console.log(e)
			openNotification('error', i.notifications.error, e.message)
			setState({ ...state, removing: false })
		})
	}
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (state.fetching) return <Loading/>
	
	return dataSyncCategories
		? <div style={{ padding: '28px 24px 0' }}>
			<Flex gap='small' wrap>
				<Button
					type="primary"
					loading={type === 'edit' ? state.updating : state.creating}
					disabled={type === 'edit' ? (state.updating || state.removing) : state.creating}
					onClick={() => form.submit()}
				>
					{type === 'edit' ? i.buttons.update : i.buttons.create}
				</Button>
				{type === 'edit' && <Popconfirm placement="top" title={i.popup.title} okText={i.popup.ok} cancelText={i.popup.cancel} onConfirm={remove}>
					<Button
						danger
						loading={state.removing}
						disabled={state.removing || state.creating || state.updating}
					>
						{i.buttons.remove}
					</Button>
				</Popconfirm>}
				<Button
					onClick={() => navigate('/categories')}
				>
					{i.buttons.back}
				</Button>
			</Flex>
			
			<Card style={{ marginTop: 16 }}>
				
				<Form
					form={form}
					name="category"
					layout="vertical"
					initialValues={type === 'edit' ? category : undefined}
					onFinish={type === 'edit' ? update : create}
				>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item name="active" label={i.labels.active} valuePropName="checked">
								<Switch checkedChildren={<CheckOutlined/>} unCheckedChildren={<CloseOutlined/>}/>
							</Form.Item>
							
							<Form.Item
								name="title"
								label={i.labels.title}
								rules={[{ required: true, message: i.required }]}
							>
								<Input placeholder={i.placeholders.title}/>
							</Form.Item>
							
							<Form.Item name="description" label={i.labels.description}>
								<Input.TextArea rows={4} placeholder={i.placeholders.description}/>
							</Form.Item>
							
							<Form.Item name="sort" label={i.labels.sort}>
								<InputNumber type="number" precision={0}/>
							</Form.Item>
						</Col>
						
						<Col span={12}>
							<Form.Item name="integrations" label={i.labels.integrations}>
								<Select
									mode="multiple"
									allowClear
									style={{ width: '100%' }}
									placeholder={i.placeholders.integrations}
									optionFilterProp="children"
								>
									{syncCategories?.map(s => <Select.Option key={s.referenceKey}>
										{s.title}
									</Select.Option>)}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			
			</Card>
		
		</div>
		: null
}

const SYNC_CATEGORIES = gql`
	query {
		getSyncCategories {
			id
			title
			referenceKey
		}
	}
`

const GET_CATEGORY = gql`
	query($id: ID!) {
		getCategory(id: $id) {
			id
			active
			title
			description
			sort
			integrations
		}
	}
`

const CREATE_CATEGORY = gql`
	mutation ($title: String, $description: String, $active: Boolean, $sort: Int, $integrations: [String]) {
		createCategory(title: $title, description: $description, active: $active, sort: $sort, integrations: $integrations) {
			id
		}
	}
`

const UPDATE_CATEGORY = gql`
	mutation ($id: ID!, $title: String, $description: String, $active: Boolean, $sort: Int, $integrations: [String]) {
		updateCategory(id: $id, title: $title, description: $description, active: $active, sort: $sort, integrations: $integrations) {
			id
		}
	}
`

const REMOVE_CATEGORY = gql`
	mutation ($id: ID!) {
		removeCategory(id: $id) {
			id
		}
	}
`
